const Hobbies = () => {
    
    return (<div className="hobbies">
    <h1>Hobbies</h1>

    <div>
      <img src="./images/biking.jpg" />
      <h2>Biking</h2>
      <p>
        I'm an avid motocycle rider and traveller. Proud owner of Bajaj
        Dominar 400.
      </p>
    </div>

    <div>
      <img src="./images/coding.jpg" />
      <h2>Coding</h2>
      <p>
        well coding gives me peace. Helps me attain order with in my
        chaotic mind.
      </p>
    </div>

    <div>
      <img src="./images/cooking.jpg" />
      <h2>Cooking</h2>
      <p>
        I got a knack for cooking too. I know cook many south-indian
        delicacies
      </p>
    </div>
  </div>)
}

export default Hobbies; 